
import {Vue, Component, VModel, Prop, Ref} from 'vue-property-decorator'
import Checkbox from '@/components/ui/Checkbox'

export type TSelectOption = {
  value: string
  label: string
}

@Component({
  components: {
    Checkbox
  }
})
export default class Select extends Vue {
  @VModel({type: [String, Array]}) modelValue!: string | string[]

  @Prop({type: Array, required: true}) options!: TSelectOption[]
  @Prop({type: Boolean, default: false}) error?: boolean
  @Prop({type: String}) placeholder?: string
  @Prop({type: Boolean, default: false}) disabled?: boolean
  @Prop({type: Boolean, default: false}) multiselect?: boolean

  @Ref('target') readonly target!: HTMLDivElement

  active: boolean = false

  get placeholderString () {
    return this.placeholder ?? ''
  }

  get label () {
    if (this.multiselect) {
      return (Array.isArray(this.modelValue) && this.modelValue.length) ? `Выбрано ${this.modelValue.length}` : this.placeholderString
    }

    const item = this.options.find(_ => _.value === this.modelValue)

    return !!this.modelValue ? item?.label : this.placeholderString
  }

  get classes () {
    return {
      'c-select_disabled': this.disabled,
      'c-select_error': this.error
    }
  }

  beforeDestroy () {
    this.removeClickListener()
  }

  handleDocumentInteraction (event: MouseEvent) {
    const isClickInsideDropdown = this.target.contains((event.target as HTMLElement))

    if (!isClickInsideDropdown && this.active) {
      this.active = false
      this.removeClickListener()
    }
  }

  removeClickListener () {
    document.removeEventListener('click', this.handleDocumentInteraction)
  }

  addClickListener () {
    document.addEventListener('click', this.handleDocumentInteraction)
  }

  onItemClick (item: TSelectOption) {
    this.active = false
    this.$emit('change', item.value)
    this.$emit('input', item.value)
  }

  onMultiselectCheckboxClick (item: TSelectOption) {
    const value = []

    if (Array.isArray(this.modelValue) && this.modelValue.length) {
      const index = this.modelValue.indexOf(item.value)
      value.push(...this.modelValue)

      if (index >= 0) {
        value.splice(index, 1)
      } else {
        value.push(item.value)
      }
    } else {
      value.push(item.value)
    }

    this.$emit('change', value)
    this.$emit('input', value)
  }

  toggle () {
    if (this.disabled) {
      return
    }

    this.active = !this.active

    if (this.active) {
      setTimeout(this.addClickListener, 150)
    }
  }

  close () {
    if (this.disabled) {
      return
    }

    if (this.active) {
      this.active = false
    }
  }
}
